import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "./custom-carousel.css";
import media from "./utils/loadMedia"; // Import the media (images and videos)

const isImage = (fileName) => /\.(png|jpe?g|svg)$/.test(fileName);
const isVideo = (fileName) => /\.mp4$/.test(fileName);

const PhotoCarousel = () => {
  const mediaArray = Object.keys(media).map((key) => ({
    src: media[key],
    type: isImage(key) ? "image" : isVideo(key) ? "video" : "unknown",
    thumbnail: isVideo(key) ? key.replace(".mp4", ".jpg") : media[key], // Assuming thumbnails for videos are stored as [videoName].jpg
  }));

  // Sort the media array to place videos first
  mediaArray.sort((a, b) => (a.type === "video" ? -1 : 1));

  return (
    <section
      id="media"
      className="media-section flex justify-center items-center h-full my-40"
    >
      <div className="photo-carousel-container max-w-screen-lg mx-auto p-4 w-full md:w-3/4">
        <h2 className="text-4xl font-bold text-teal-300 font-georgia text-center">
          <strong>Media Gallery</strong>
        </h2>
        <br />
        <Carousel
          showThumbs={true}
          // autoPlay
          infiniteLoop
          showStatus={false}
          dynamicHeight
          // interval={5000} // Set interval to 5000 milliseconds (5 seconds)
          renderThumbs={() =>
            mediaArray.map((mediaItem, index) => (
              <img
                key={index}
                src={
                  mediaItem.type === "video"
                    ? `/media/${mediaItem.thumbnail}`
                    : mediaItem.thumbnail
                }
                alt={`Thumbnail ${index}`}
                className="object-cover h-16 w-16"
                loading="lazy"
              />
            ))
          }
        >
          {mediaArray.map((mediaItem, index) => (
            <div key={index} className="carousel-slide">
              {mediaItem.type === "image" ? (
                <img
                  src={mediaItem.src}
                  srcSet={`
                  ${mediaItem.src}?w=600 600w,
                  ${mediaItem.src}?w=1200 1200w,
                  ${mediaItem.src}?w=1800 1800w
                `}
                  sizes="(max-width: 600px) 600px, 
                      (max-width: 1200px) 1200px, 
                      1800px"
                  alt={`Slide ${index}`}
                  className="object-contain h-80 md:h-96 lg:h-128"
                  loading="lazy"
                />
              ) : mediaItem.type === "video" ? (
                <video
                  className="w-full max-w-full h-80 md:h-96 lg:h-128"
                  controls
                  loading="lazy"
                >
                  <source src={mediaItem.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default PhotoCarousel;
