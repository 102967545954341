// utils/loadMedia.js
const importAll = (r) => {
  let media = {};
  r.keys().map((item, index) => {
    media[item.replace("./", "")] = r(item);
    return null;
  });
  return media;
};

const media = importAll(
  require.context("../../../public/media", false, /\.(png|jpe?g|svg|mp4|jpg)$/)
);

export default media;
