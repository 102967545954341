import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Reviews = ({ toggleModal }) => {
  const imagePaths = [
    "reviews/review_3.png",
    "reviews/review_1.png",
    "reviews/review_2.png",
    "reviews/review_4.png",
  ];

  return (
    <section
      id="reviews"
      className="reviews-section flex justify-center items-center h-full my-40"
    >
      <div className="container mx-auto px-4">
        <div className="reviews-content flex flex-col-reverse md:flex-col-reverse lg:flex-row-reverse justify-evenly items-center">
          <div className="narrative-container w-full md:w-3/4 lg:w-1/2 mb-8 md:mb-0">
            <div className="about-text text-center">
              <h2 className="text-4xl font-bold text-teal-300 font-georgia">
                <strong>Reviews</strong>
              </h2>
              <br />
              <div className="reviews-text text-center md:text-left">
                <div className="grid grid-cols-2 gap-4">
                  {imagePaths.map((path, index) => (
                    <Zoom key={index}>
                      <img
                        src={path}
                        alt={`Review ${index + 1}`}
                        className="w-full h-48 md:h-64 object-cover"
                        onError={(e) => {
                          console.log(`Image failed to load: ${path}`);
                          e.target.style.display = "none";
                        }}
                      />
                    </Zoom>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full md:w-3/4 lg:w-1/4 mb-8">
            <img
              src="reviews/host_info.png"
              alt="host_info"
              className="w-full h-full object-cover mb-4"
            />
            <button
              className="bg-teal-300 hover:bg-teal-400 text-white font-bold py-2 px-4 rounded-full w-full md:w-auto"
              onClick={toggleModal}
            >
              Got a question? Contact Us!
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
