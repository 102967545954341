import React, { useState, useEffect } from "react";

const Navbar = ({ toggleModal }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const isVisible =
      prevScrollPos > currentScrollPos || currentScrollPos === 0;

    setPrevScrollPos(currentScrollPos);
    setVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleNavItemClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
    setIsDrawerOpen(false);
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 flex items-center justify-between px-6 z-10 ${visible ? "opacity-100 transition-opacity duration-500" : "opacity-0 transition-opacity duration-500"}`}
    >
      <a href="#" onClick={(e) => handleHomeClick(e)}>
        <div className="logo text-white text-2xl font-bold">
          <img
            src="/sal_n_turq_logo.svg"
            alt="Logo"
            style={{ width: "70px" }}
          />
        </div>
      </a>
      <button
        className="block md:hidden text-white focus:outline-none"
        onClick={toggleDrawer}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isDrawerOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
          ></path>
        </svg>
      </button>
      <ul className="hidden md:flex space-x-10 px-4">
        <li>
          <a
            href="#"
            className="text-gray-300 font-bold hover:text-teal-300 no-underline transition-colors duration-300 font-georgia"
            onClick={(e) => handleNavItemClick(e, "about")}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="#"
            className="text-gray-300 font-bold hover:text-teal-300 no-underline transition-colors duration-300 font-georgia"
            onClick={(e) => handleNavItemClick(e, "media")}
          >
            Media
          </a>
        </li>
        <li>
          <a
            href="#"
            className="text-gray-300 font-bold hover:text-teal-300 no-underline transition-colors duration-300 font-georgia"
            onClick={(e) => handleNavItemClick(e, "reviews")}
          >
            Reviews
          </a>
        </li>
        <li>
          <button
            className="text-gray-300 font-bold hover:text-teal-300 no-underline transition-colors duration-300 font-georgia"
            onClick={toggleModal}
          >
            Contact
          </button>
        </li>
      </ul>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 z-50 transform ${isDrawerOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 md:hidden`}
      >
        <div className="flex flex-col items-center justify-center h-full">
          <a
            href="#"
            className="text-white text-2xl py-4 font-georgia"
            onClick={(e) => handleNavItemClick(e, "about")}
          >
            About
          </a>
          <a
            href="#"
            className="text-white text-2xl py-4 font-georgia"
            onClick={(e) => handleNavItemClick(e, "media")}
          >
            Media
          </a>
          <a
            href="#"
            className="text-white text-2xl py-4 font-georgia"
            onClick={(e) => handleNavItemClick(e, "reviews")}
          >
            Reviews
          </a>
          <button
            className="text-white text-2xl py-4 font-georgia"
            onClick={toggleModal}
          >
            Contact
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
