const HeroSection = ({ toggleModal }) => {
  return (
    <div className="hero-section h-screen flex items-center justify-center relative">
      <video autoPlay loop muted playsInline className="background-video">
        <source src="zoom_out_center.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-4xl md:text-6xl lg:text-9xl font-bold mb-4 title">
          Welcome to the Beach Adventure!
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl mb-8 font-georgia subtitle">
          Get ready for an unforgettable beach vacation filled with sun, sand,
          and adventure.
        </p>
        <a href="https://www.airbnb.com/h/salandturq">
          <button className="bg-white text-teal-500 py-3 px-6 rounded-full shadow-lg transform hover:scale-105 hover:bg-teal-500 hover:text-white transition-transform duration-300 ease-in-out font-bold">
            Book With Us On Airbnb
          </button>
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
