import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faInstagram,
  faAirbnb,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer flex justify-center p-4 mt-4 bg-teal-500 h-full">
      <div className="flex flex-col">
        <ul className="navlist flex justify-evenly">
          <li>
            <a href="https://www.tiktok.com/@salandturq?_t=8n2PfkjnvnW&_r=1">
              <div className="w-4 text-white scale-125 hover:text-secondary hover:scale-150 ease-in-out duration-300">
                <Icon icon={faTiktok} />
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/salandturq/?igsh=cmp2cnU5cjJ5dW5m&utm_source=qr">
              <div className="w-4 text-white scale-125 hover:text-secondary hover:scale-150 ease-in-out duration-300">
                <Icon icon={faInstagram} />
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.airbnb.com/h/salandturq">
              <div className="w-4 text-white scale-125 hover:text-secondary hover:scale-150 ease-in-out duration-300">
                <Icon icon={faAirbnb} />
              </div>
            </a>
          </li>
        </ul>
        <p className="text-center text-gray-500 text-sm mt-2">
          &copy; 2024 Seed Bar, LLC. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
