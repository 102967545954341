import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import Reviews from "./components/Reviews";
import Footer from "./components/Footer";
import PhotoCarousel from "./components/PhotoCarousel";
import ContactForm from "./components/ContactForm";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="App h-screen flex flex-col overflow-hidden">
        <Navbar toggleModal={toggleModal} />
        <HeroSection toggleModal={toggleModal} className="flex-grow" />
      </div>
      <About />
      <PhotoCarousel />
      <Reviews toggleModal={toggleModal} />
      <Footer />
      <ContactForm isOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );
}

export default App;
