import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="about-section flex justify-center items-center h-full my-40"
    >
      <div className="container mx-auto px-4">
        <div className="about-content flex flex-col md:flex-col lg:flex-row justify-evenly items-center">
          <div className="narrative-container w-full md:w-3/4 lg:w-2/5 mb-8 md:mb-0">
            <div className="about-text text-center md:text-left">
              <h2 className="text-4xl font-bold text-teal-300 font-georgia">
                <strong>About Us</strong>
              </h2>
              <br />
              <p>
                We saw Sal & Turq as a serene oceanfront camper lodge, offering
                a chance to unwind in nature's simplicity while enjoying
                home-like comforts. We built it from scratch, locating it on one
                of the last pristine, untouched beaches on the island's west.
                Our idea is to help people reconnect with nature and appreciate
                the simple beauty of the landscape.
              </p>
              <p>
                The concept was born from our desire to preserve the land as it
                has always been, minimizing our environmental impact to
                highlight the beach’s terrain. However, we understand that
                nothing we create truly matters without people to share it with.
                We wholeheartedly welcome your visit.
              </p>
              <p>
                As for the construction of Buxton, it was built in San Pedro
                Sula and is 100% Honduran. You might wonder why we named it
                Buxton – here's a quick hint: think 'Shawshank Redemption.' It
                took us approximately seven months to complete, with a team of
                ten people. The design was inspired by 1930s American Airstreams
                and our love for the nomadic life. We take pride in the fact
                that everything, including the metal casing, was handcrafted in
                our workshop and designed by us.
              </p>
              <p>
                We'd like to extend a special thanks to our architect, Linda,
                and to all the skilled craftsmen who contributed to this very
                special project.
              </p>
              <p>With Love,</p>
              <img src="sal_n_turq_logo_text.svg" alt="Sal & Turq" />
            </div>
          </div>
          <div className="image-container shadow-2xl w-full md:w-3/4 lg:w-1/3">
            <img src="narrow_buxton.jpeg" alt="About Us" className="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
